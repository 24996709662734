<template>

<app-wrapper class="services">

	<app-wrapper :content="true" :text="true" theme="blue" class="services-content" :title="$state.page.services_title">

		<div class="services-content-list">

			<app-service v-for="(item, index) in $state.page.services" :pill="window.is.mobile" :key="index" :item="item" />

		</div>

	</app-wrapper>

</app-wrapper>

</template>

<script>

export default {

}

</script>

<style scoped>

.services {
	padding: 64px 0px 128px 0px;
	background-color: #485CC7;
}

.is-tablet .services {
	padding: 48px 0px 96px 0px;
}

.is-mobile .services {
	padding: 32px 0px 32px 0px;
}

.services-content-list {
	display: grid;
	margin-top: 64px;
	grid-template-columns: 1fr 1fr 1fr;
	grid-gap: 40px;
}

.is-tablet .services-content-list {
	margin-top: 48px;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr;
}

.is-mobile .services-content-list {
	margin-top: 32px;
	grid-gap: 10px;
	grid-template-columns: 1fr;
}

</style>
